import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
    query {
        foto: file(relativePath: { eq: "foto-gik.png" }) {
            childImageSharp {
                fluid(maxWidth: 675) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        logo: file(relativePath: { eq: "logo-gik.png" }) {
            childImageSharp {
                fluid(maxWidth: 675) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

const Specialisms = ({ data }) => (
  <Layout pageInfo={{ pageName: "specialisaties", pageTitle: "Specialisaties", bannerTitle: "website-header-01.png" }}>
    <Seo title="Specialisaties" />

    <Container>
      <section className="container" id="specialisms">
        <Row>
          <Col>
            <h2>Algemeen</h2>
            <div className="block">
              <p>Met vrijwel elk probleem kun je bij mij als kind- en gezinscoach terecht, de opleiding die ik heb
                gevolgd en de werkervaring die ik heb opgedaan is breed. Het voorgaande neemt niet weg dat er een aantal
                onderwerpen zijn die mij, door mijn (persoonlijke) ervaringen, extra aanspreken.
              </p>
            </div>

            <h2>Medische problematiek</h2>
            <div className="block">
              <p>
                Een medische achtergrond bij een kind of een ouder heeft vaak een grote impact op het gehele gezin:
                ziekenhuisbezoeken, zorgen bij alle gezinsleden, onduidelijkheid en onzekerheid over hoe het verder zal
                gaan etc. De gevolgen hiervan zijn echter niet altijd gelijk duidelijk: een kind plast plots weer in
                zijn broek of slaapt moeilijk en op school loopt het ineens niet meer zo goed. Of als ouder treedt je
                misschien wat minder consequent op waardoor een kind de grenzen meer opzoekt of je kampt met
                schuldgevoelens en (over)bezorgdheid.
              </p>

              <p>
                Uit eigen ervaring weet ik welk effect gezondheidsproblemen van een ouder op een gezin kunnen hebben.
                Maar ook zorgen om de gezondheid van je kind zijn mij bekend. Onze zoon is in 2013 geboren met een
                aangeboren aandoening die tijdens de zwangerschap niet bekend was en zijn situatie is kritiek geweest.
                Zijn aandoening brengt voor mij als ouder nog steeds dagelijks zorgen met zich mee, maar vooral de
                eerste jaren waren pittig. Tijdens opnames in het ziekenhuis was er alle begeleiding die we nodig
                hadden, maar eenmaal thuis sta je er toch alleen voor. Wat ik destijds vooral heb gemist is iemand die
                mij kon helpen met dingen waar ik in het dagelijks leven tegenaan liep. Geen familie of een vriendin,
                die zijn vaak te emotioneel betrokken. Maar ook geen arts of specialist omdat het niet altijd direct een
                medisch probleem was maar bijvoorbeeld wel een gevolg van het feit dat zowel onze zoon als wijzelf in
                die periode veel hebben meegemaakt. Ook andere vormen van hulpverlening, zoals een psycholoog of een
                maatschappelijk werker, leken dan toch niet passend en net een stap te ver. Even praten of overleggen
                met iemand die in eenzelfde soort situatie heeft gezeten en weet waar je tegenaan loopt, wat voor de
                omgeving soms moeilijk te begrijpen is, zou echt een uitkomst zijn geweest. Na veel worstelen en zoeken
                naar de juiste hulp voor onze zoon kwamen wij uiteindelijk bij een kind- en gezinscoach terecht: d.m.v.
                spelen en leuke activiteiten kreeg onze zoon wat hij nodig had en wij als ouders kregen handvaten
                geboden en soms zelf een spiegel voorgehouden. En wat bleek: deze hulp bracht dus niet alleen een
                positieve ontwikkeling voor hem, maar voor ons hele gezin!
              </p>

              <p>
                Tijdens mijn opleiding tot kind- en gezinscoach ben ik gaan nadenken over wat voor coach ik graag zou
                willen zijn. Dit heeft mij doen besluiten dat ik het gat dat ik heb ervaren in de hulpverlening op wil
                vullen en dat ik graag gezinnen waarin sprake is (geweest) van medische problematiek wil begeleiden.
                Hoewel elke situatie anders is, denk ik dat mijn eigen ervaringen op dit gebied een grote meerwaarde
                zijn!
              </p>
            </div>

            <h2>Geboortepatronen</h2>
            <div className="block">
              <p>Vaak wordt er niet zo over nagedacht, maar de manier waarop je bent geboren vormt de basis voor je
                gevoel van veiligheid, eigenwaarde en de manier waarop je je kunt hechten. Daarnaast wordt je karakter
                al gevormd in de baarmoeder. Al deze factoren zijn van invloed op je gedrag. Gedrag is dan ook, anders
                dan vaak wordt gesteld, aangeboren en niet aangeleerd. Daarnaast is gedrag, wanneer je ernaar kijkt
                vanuit de geboortepsychologie, altijd logisch: het is een uiting van eerdere overweldigende ervaringen
                die niet zijn opgelost. Wanneer je gedrag écht wilt begrijpen, moet dan ook verder worden gekeken dan
                het gedrag alleen. Als we kijken naar de achterliggende behoefte van gedrag, blijkt het gedrag ineens
                logisch te zijn! Wanneer gedrag begrepen wordt en je de behoefte hierachter kent, kun je kijken hoe aan
                die behoefte tegemoet gekomen kan worden. Om de behoefte achter het gedrag in kaart te brengen, maar ik
                gebruik van de door Marjella Duiker en Martine Bökkers ontwikkelde methode Geboorte In Kaart.</p>
              <p>Bij Geboorte In Kaart wordt gebruik gemaakt van een vragenlijst, kaartenset en handboek. De vragenlijst
                wordt vooraf ingevuld en door mij doorgenomen. Ook wordt een sessie gepland waarbij we dieper ingaan op
                je geboorteverhaal, die ongeveer twee tot drie uur duurt. Aan de hand van vragenkaarten, fotokaarten,
                kaarten met gedrag of kenmerken passend bij bepaalde geboortepatronen en kwaliteitskaarten breng ik het
                geboorteverhaal in kaart. Na de sessie werk ik dit geboorteverhaal verder uit en ontvang je een
                uitgebreide rapportage waarin jouw geboorteverhaal is uitgewerkt: een waardevol document over het begin
                van het leven van je kind! Ten slotte gaan we aan de hand van het geboorteverhaal natuurlijk aan de slag
                met de behoeftes die duidelijk zijn geworden en dus de hulpvraag die er ligt. </p>
              <p>Natuurlijk heeft hoe wij als ouders zijn en handelen ook invloed op ons kind. Daarom kan het ook heel
                waardevol zijn om te kijken naar je eigen geboortepatronen! Wanneer hiervoor interesse bestaat kan hier
                zeker naar worden gekeken. </p>
            </div>

            <Row>
              <Col xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 1 }}>
                <Img className="image-padding" fluid={data.foto.childImageSharp.fluid} alt="Foto - Geboorte in Kaart" />
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4, offset: 2 }}>
                <Img className="image-padding" fluid={data.logo.childImageSharp.fluid} alt="Logo - Geboorte in Kaart" />
              </Col>
            </Row>

            <h2>Multiproblematiek binnen een gezin</h2>
            <div className="block">
              <p>In een gezin kan sprake zijn van multiproblematiek, wat inhoudt dat er binnen een gezin bij meerdere
                gezinsleden verschillende problemen voordoen. Deze multiproblematiek kan voor alle gezinsleden grote
                gevolgen hebben wanneer hier niets mee wordt gedaan.
              </p>

              <p>
                Voordat ik de opleiding tot kind- en gezinscoach ben gaan volgen heb ik jarenlang bij de rechtbank in
                team Jeugd gewerkt. Daar heb ik zaken behandeld met betrekking tot minderjarigen die - om verschillende
                redenen - in hun ontwikkeling werden bedreigd. Eén van die redenen was de aanwezigheid van
                multiproblematiek binnen hun gezin. Natuurlijk komen dit soort zaken niet zomaar bij de rechtbank
                terecht, daar is vaak al veel aan voorafgegaan. Om te voorkomen dat het zover komt is het verstandig om
                al bij de eerste signalen de juiste hulpverlening in te schakelen. Begeleiding van een kind- en
                gezinscoach is een laagdrempelige vorm van hulpverlening voor zowel een kind als het hele gezin die in
                dit geval uitkomst kan bieden. Uitgangpunt hierbij is dat juist wordt gekeken naar de krachten en de
                kwaliteiten van het gezin en de gezinsleden individueel welke kunnen worden ingezet om de door het gezin
                gewenste situatie te bereiken.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default Specialisms

